import React from 'react';
import './Legend.css';

const Legend = ({ minValue, maxValue }) => {
  return (
    <div className="legend-container">
      <div className="legend-title">Legend</div>
      <div className="legend-labels-vertical">
        <span>{minValue.toFixed(1)}</span>
        <div className="legend-gradient-vertical"></div>
        <span>{maxValue.toFixed(1)}</span>
      </div>
    </div>
  );
};

export default Legend;
