import React from 'react';
import './App.css';

const ScenarioSelector = ({ scenarios, selectedScenario, onScenarioChange }) => {
  return (
    <div className="scenario-selector">
      <label htmlFor="scenario-select">Scenario:</label>
      <select id="scenario-select" value={selectedScenario} onChange={onScenarioChange}>
        {scenarios.map((scenario) => (
          <option key={scenario} value={scenario}>
            {scenario}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ScenarioSelector;
