import React from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import './MatrixTable.css';

// Import the Handsontable cell types
import { registerAllModules } from 'handsontable/registry';
registerAllModules();

const MatrixTable = ({ data, locations }) => {
  const formattedData = locations.map(origin => {
    const row = [];
    let rowTotal = 0;
    locations.forEach(destination => {
      const cellData = data.filter(item => item.origin === origin && item.destination === destination);
      const value = cellData.reduce((sum, item) => sum + Number(item.value), 0);
      row.push(value);
      rowTotal += value;
    });
    row.push(rowTotal);
    return row;
  });

  const totalRow = locations.reduce((acc, loc, index) => {
    const totalValue = formattedData.reduce((sum, row) => sum + parseFloat(row[index]), 0);
    acc.push(totalValue);
    return acc;
  }, []);

  totalRow.push(formattedData.reduce((sum, row) => sum + parseFloat(row[locations.length]), 0));
  formattedData.push(totalRow);

  const headers = [...locations, 'Total'];
  const rowheaders = [...locations, 'Total'];

  const lastRowIndex = formattedData.length - 1;
  const lastColIndex = headers.length - 1;

  const columnWidths = new Array(headers.length).fill(100); // Default column width

  return (
    <div className="matrix-table-container">
      <div className="inner-table-container">
        <HotTable
          data={formattedData}
          colHeaders={headers}
          rowHeaders={rowheaders} // Remove row numbers
          width="100%"
          height="350px"
          fixedRowsTop={0}
          fixedColumnsLeft={0}
          licenseKey="non-commercial-and-evaluation" // Use appropriate license key if needed
          className="handsontable"
          readOnly={true} // Make cells read-only
          colWidths={columnWidths}
          cells={(row, col) => {
            const cellProperties = {
              readOnly: true, // Ensure all cells are read-only
              className: 'htCenter ht-middle', // Center align text
              type: 'numeric', // Set cell type to numeric
              numericFormat: {
                pattern: '0.0', // Format to one decimal place
              }
            };
            if (row === lastRowIndex || col === lastColIndex) {
              cellProperties.className += ' ht-bold';
            }
            return cellProperties;
          }}
        />
      </div>
    </div>
  );
};

export default MatrixTable;
