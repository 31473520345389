import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const BarChartComponent = ({ title, totals }) => {
  const data = Object.keys(totals).map(location => ({
    name: location,
    value: parseFloat(totals[location]).toFixed(1)
  }));

  // Calculate the maximum value from the data
  const maxValue = Math.max(...data.map(d => d.value));

  return (
    <div className="chart-container">
      <h3>{title}</h3>
      <BarChart
        width={300}
        height={300}
        data={data}
        layout="vertical"
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[0, Math.ceil(maxValue + (maxValue * 0.1))]} // Set domain dynamically and round up
          tickFormatter={(tick) => Math.round(tick)} // Format ticks to be integers
        />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default BarChartComponent;
