import React from 'react';
import { Map } from 'react-map-gl';
import { DeckGL } from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import { scaleLinear } from 'd3-scale';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2RldjE5OTIiLCJhIjoiY2tjeTdtOGFyMDFhMDJ4bW92bDEzYXp4dyJ9.m4B9mvJhnHcVLodLlqdg4A';

const MapComponent = ({ title, geojsonData, totals, initialViewState }) => {
  const colorScale = scaleLinear()
    .domain([Math.min(...Object.values(totals)), Math.max(...Object.values(totals))])
    .range([[255, 255, 204], [255, 0, 0]]);

  const layers = [
    new GeoJsonLayer({
      id: 'geojson-layer',
      data: geojsonData,
      filled: true,
      getFillColor: (d) => {
        const zoneName = d.properties.ID;
        const value = totals[zoneName] || 0;
        return colorScale(value);
      },
      stroked: false,
      getLineColor: [255, 255, 255],
      getLineWidth: 1,
      opacity: 0.6,
      updateTriggers: {
        getFillColor: [totals]
      },
      pickable: true
    })
  ];

  const getTooltip = ({ object }) => {
    if (object) {
      const zoneName = object.properties.ID;
      const value = totals[zoneName] || 0;
      return {
        html: `<div>ID: ${zoneName}</div><div>Value: ${value.toFixed(1)}</div>`,
      };
    }
    return null;
  };

  return (
    <div className="map-container-dash">
      <h3>{title}</h3>
      <DeckGL
        initialViewState={initialViewState}
        controller={true}
        getTooltip={getTooltip}
        layers={layers}
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <Map 
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN} 
          mapStyle="mapbox://styles/mapbox/dark-v11" // Add this line to define the basemap style
        />
      </DeckGL>
    </div>
  );
};

export default MapComponent;