import React from 'react';
import SummaryTable from './SummaryTable';
import SummaryChart from './SummaryChart';

const SummaryPage = ({ scenarios, modes }) => {
  return (
    <div className="summary-page">
      <div className="data-table-wrapper">
        <SummaryTable scenarios={scenarios} modes={modes} />
      </div>
      <div className="chart-container">
        <SummaryChart scenarios={scenarios} modes={modes} />
      </div>
    </div>
  );
};

export default SummaryPage;
