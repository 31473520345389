import React from 'react';
import DataTable from 'react-data-table-component';
import './SummaryTable.css';

const SummaryTable = ({ scenarios, modes }) => {
  const columns = [
    { name: 'Scenario', selector: row => row.scenario, sortable: true },
    ...Object.keys(modes).map(modeKey => ({
      name: modes[modeKey],
      selector: row => row[modes[modeKey]],
      sortable: true,
      format: row => row[modes[modeKey]].toFixed(1)
    })),
    { name: 'Total', selector: row => row.total, sortable: true, format: row => row.total.toFixed(1)}
  ];

  const formattedData = Object.keys(scenarios).map(scenarioName => {
    const scenario = scenarios[scenarioName];
    const row = { scenario: scenarioName };
    let total = 0;
    Object.keys(modes).forEach(modeKey => {
      const value = scenario[modeKey] ? scenario[modeKey].reduce((sum, item) => sum + Number(item.value), 0) : 0;
      row[modes[modeKey]] = value;
      total += value;
    });
    row.total = total;
    return row;
  });

  return (
    <div className="summary-table">
      <DataTable
        // title="Summary Table"
        columns={columns}
        data={formattedData}
        pagination={false}
        highlightOnHover
        striped
      />
    </div>
  );
};

export default SummaryTable;
