import React, { useState, useEffect } from 'react';
import DeckGL from '@deck.gl/react';
import { Map } from 'react-map-gl';
import { GeoJsonLayer } from '@deck.gl/layers';
import { scaleLinear } from 'd3-scale';
import Legend from './Legend'; // Import the Legend component
import './MapPage.css';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2RldjE5OTIiLCJhIjoiY2tjeTdtOGFyMDFhMDJ4bW92bDEzYXp4dyJ9.m4B9mvJhnHcVLodLlqdg4A'; // Add your Mapbox access token here

const MapPage = ({ geojsonData, geojsonCenter, geojsonZoom, scenarios, modes }) => {
  const [selectedScenario, setSelectedScenario] = useState(Object.keys(scenarios)[0]);
  const [selectedMode, setSelectedMode] = useState(Object.keys(modes)[0]);
  const [colorTheme, setColorTheme] = useState('Origin Total'); // Default color theme
  const [clickedZone, setClickedZone] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    if (colorTheme === 'By Origin' && clickedZone) {
      const filtered = scenarios[selectedScenario][selectedMode].filter(row => row.origin === clickedZone);
      setFilteredData(filtered);
    } else if (colorTheme === 'By Destination' && clickedZone) {
      const filtered = scenarios[selectedScenario][selectedMode].filter(row => row.destination === clickedZone);
      setFilteredData(filtered);
    } else {
      setFilteredData(scenarios[selectedScenario][selectedMode]);
    }
  }, [colorTheme, clickedZone, selectedScenario, selectedMode]);

  const calculateColorScale = (values) => {
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return {
      scale: scaleLinear()
        .domain([minValue, maxValue])
        .range(['white', 'red']),
      minValue,
      maxValue
    };
  };

  const getValuesForColorTheme = () => {
    let values = [];
    if (colorTheme === 'Origin Total') {
      values = geojsonData.features.map(feature => {
        const id = feature.properties.ID;
        return scenarios[selectedScenario][selectedMode]
          .filter(row => row.origin === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      });
    } else if (colorTheme === 'Destination Total') {
      values = geojsonData.features.map(feature => {
        const id = feature.properties.ID;
        return scenarios[selectedScenario][selectedMode]
          .filter(row => row.destination === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      });
    } else if (colorTheme === 'By Origin' && clickedZone) {
      values = geojsonData.features.map(feature => {
        const id = feature.properties.ID;
        return filteredData
          .filter(row => row.destination === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      });
    } else if (colorTheme === 'By Destination' && clickedZone) {
      values = geojsonData.features.map(feature => {
        const id = feature.properties.ID;
        return filteredData
          .filter(row => row.origin === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      });
    }
    return values;
  };

  const { scale: colorScale, minValue, maxValue } = calculateColorScale(getValuesForColorTheme());

  const layers = [
    new GeoJsonLayer({
      id: 'geojson-layer',
      data: geojsonData,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      opacity: 0.6,
      getLineWidth: 5,
      getLineColor: [0, 0, 0],
      getFillColor: d => {
        const id = d.properties.ID;
        let value = 0;

        if (colorTheme === 'Origin Total') {
          value = scenarios[selectedScenario][selectedMode]
            .filter(row => row.origin === id)
            .reduce((sum, row) => sum + Number(row.value), 0);
        } else if (colorTheme === 'Destination Total') {
          value = scenarios[selectedScenario][selectedMode]
            .filter(row => row.destination === id)
            .reduce((sum, row) => sum + Number(row.value), 0);
        } else if (colorTheme === 'By Origin' && clickedZone) {
          value = filteredData
            .filter(row => row.destination === id)
            .reduce((sum, row) => sum + Number(row.value), 0);
        } else if (colorTheme === 'By Destination' && clickedZone) {
          value = filteredData
            .filter(row => row.origin === id)
            .reduce((sum, row) => sum + Number(row.value), 0);
        }

        const color = colorScale ? colorScale(value) : 'white';
        const [r, g, b] = color.match(/\d+/g).map(Number);
        return [r, g, b, 255]; // Convert color to RGBA array
      },
      onClick: info => {
        if (colorTheme === 'By Origin' || colorTheme === 'By Destination') {
          setClickedZone(info.object.properties.ID);
        }
      },
      updateTriggers: {
        getFillColor: [colorTheme, filteredData, clickedZone, colorScale]
      }
    })
  ];

  const getTooltip = ({ object }) => {
    if (object) {
      const id = object.properties.ID;
      let value = 0;

      if (colorTheme === 'Origin Total') {
        value = scenarios[selectedScenario][selectedMode]
          .filter(row => row.origin === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      } else if (colorTheme === 'Destination Total') {
        value = scenarios[selectedScenario][selectedMode]
          .filter(row => row.destination === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      } else if (colorTheme === 'By Origin' && clickedZone) {
        value = filteredData
          .filter(row => row.destination === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      } else if (colorTheme === 'By Destination' && clickedZone) {
        value = filteredData
          .filter(row => row.origin === id)
          .reduce((sum, row) => sum + Number(row.value), 0);
      }

      return {
        html: `<div>ID: ${id}</div><div>Value: ${value.toFixed(1)}</div>`,
      };
    }
    return null;
  };

  return (
    <div className="map-page">
      <div className="navbar-placeholder"></div>
      <div className="content">
        <div className="sidebar">
          <h2>Map Options</h2>
          <div className="option-group">
            <label>Scenario:</label>
            <select value={selectedScenario} onChange={(e) => setSelectedScenario(e.target.value)}>
              {Object.keys(scenarios).map(scenario => (
                <option key={scenario} value={scenario}>{scenario}</option>
              ))}
            </select>
          </div>
          <div className="option-group">
            <label>Mode:</label>
            <select value={selectedMode} onChange={(e) => setSelectedMode(e.target.value)}>
              {Object.keys(modes).map(mode => (
                <option key={mode} value={mode}>{modes[mode]}</option>
              ))}
            </select>
          </div>
          <div className="option-group">
            <label>Theme:</label>
            <select value={colorTheme} onChange={(e) => setColorTheme(e.target.value)}>
              <option value="Origin Total">Origin Total</option>
              <option value="Destination Total">Destination Total</option>
              <option value="By Origin">By Origin</option>
              <option value="By Destination">By Destination</option>
            </select>
            {colorTheme === 'By Origin' && (
              <div className="color-theme-info">Click an Origin Zone</div>
            )}
            {colorTheme === 'By Destination' && (
              <div className="color-theme-info">Click a Destination Zone</div>
            )}
          </div>
        </div>
        <div className="map-container-full-page">
          <DeckGL
            initialViewState={{
              longitude: geojsonCenter[0],
              latitude: geojsonCenter[1],
              zoom: geojsonZoom,
              pitch: 0,
              bearing: 0
            }}
            controller={true}
            layers={layers}
            getTooltip={getTooltip}
          >
            <Map
              mapStyle="mapbox://styles/mapbox/dark-v11"
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              style={{ width: '100%', height: '100%' }}
            />
          </DeckGL>
          <Legend minValue={minValue} maxValue={maxValue} /> {/* Add the Legend component */}
        </div>
      </div>
    </div>
  );
};

export default MapPage;
