import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from './Home';
import Dashboard from './Dashboard';
import MapPage from './MapPage';
import SummaryPage from './SummaryPage'; // Import the new SummaryPage
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const App = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filters, setFilters] = useState({});
  const [modes, setModes] = useState({});
  const [scenarios, setScenarios] = useState({});
  const [geojsonFile, setGeojsonFile] = useState(null);
  const [geojsonCenter, setGeojsonCenter] = useState(null);
  const [geojsonZoom, setGeojsonZoom] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState("");

  const handleFilesUploaded = ({ modes, scenarios, files, geojsonFile, geojsonCenter, geojsonZoom }) => {
    setUploadedFiles(files);
    setModes(modes);
    setScenarios(scenarios);
    setGeojsonFile(geojsonFile);
    setGeojsonCenter(geojsonCenter);
    setGeojsonZoom(geojsonZoom);
    const initialFilters = {};
    Object.keys(modes).forEach(mode => {
      initialFilters[mode] = {};
    });
    setFilters(initialFilters);

    // Set the first scenario as selected by default
    const firstScenario = Object.keys(scenarios)[0];
    setSelectedScenario(firstScenario);
  };

  const handleScenarioChange = (scenario) => {
    setSelectedScenario(scenario);
  };

  const handleFilterChange = (mode, newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [mode]: newFilters
    }));
  };

  return (
    <Router>
      <div className="app">
        <nav>
          <ul className="nav-tabs">
            <li>
              <NavLink to="/" end className={({ isActive }) => isActive ? "active-tab" : ""}>home</NavLink>
            </li>
            {uploadedFiles.length > 0 && (
              <li>
                <NavLink to="/summary" className={({ isActive }) => isActive ? "active-tab" : ""}>summary</NavLink>
              </li>
            )}
            {Object.keys(modes).map((modeKey) => (
              <li key={modeKey}>
                <NavLink to={`/${modeKey}`} className={({ isActive }) => isActive ? "active-tab" : ""}>
                  {modes[modeKey]}
                </NavLink>
              </li>
            ))}
            {geojsonFile && (
              <li>
                <NavLink to="/map" className={({ isActive }) => isActive ? "active-tab" : ""}>map</NavLink>
              </li>
            )}
          </ul>
          <img src="/ml_logo.png" alt="Company Logo" />
        </nav>
        <Routes>
          <Route path="/" element={<Home onFilesUploaded={handleFilesUploaded} />} />
          {uploadedFiles.length > 0 && (
            <Route
              path="/summary"
              element={<SummaryPage scenarios={scenarios} modes={modes} />}
            />
          )}
          {Object.keys(modes).map((modeKey) => (
            <Route
              key={modeKey}
              path={`/${modeKey}`}
              element={
                <Dashboard
                  key={modeKey}
                  mode={modeKey}
                  selectedScenario={selectedScenario}
                  onScenarioChange={handleScenarioChange}
                  filters={filters[modeKey]}
                  onFilterChange={(newFilters) => handleFilterChange(modeKey, newFilters)}
                  scenarios={scenarios}
                  geojsonData={geojsonFile ? geojsonFile.data : null}
                  geojsonCenter={geojsonCenter}
                  geojsonZoom={geojsonZoom}
                />
              }
            />
          ))}
          {geojsonFile && (
            <Route
              path="/map"
              element={
                <MapPage
                  geojsonData={geojsonFile ? geojsonFile.data : null}
                  geojsonCenter={geojsonCenter}
                  geojsonZoom={geojsonZoom}
                  scenarios={scenarios}
                  modes={modes}
                />
              }
            />
          )}
          {/* Fallback route to handle invalid paths */}
          <Route path="*" element={<Home onFilesUploaded={handleFilesUploaded} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
