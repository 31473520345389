import React from 'react';

const FilterPanel = ({ title, options, handleCheckboxChange, checkedOptions }) => {
  return (
    <div className="filter-panel">
      <h3>{title}</h3>
      {options.map((option) => (
        <div key={option}>
          <input
            type="checkbox"
            id={`${title}-${option}`}
            name={option}
            checked={checkedOptions[option] || false}
            onChange={(e) => handleCheckboxChange(e, title)}
          />
          <label htmlFor={`${title}-${option}`}>{option}</label>
        </div>
      ))}
    </div>
  );
};

export default FilterPanel;