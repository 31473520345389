import React, { useState, useEffect, useRef } from 'react';
import MatrixTable from './MatrixTable'; // Update import to MatrixTable
import FilterPanel from './FilterPanel';
import BarChartComponent from './BarChartComponent';
import MapComponent from './MapComponent';
import ScenarioSelector from './ScenarioSelector';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const Dashboard = ({ mode, selectedScenario, onScenarioChange, filters, onFilterChange, scenarios, geojsonData, geojsonCenter, geojsonZoom }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [originTotals, setOriginTotals] = useState({});
  const [destinationTotals, setDestinationTotals] = useState({});
  const filtersRef = useRef(filters);

  useEffect(() => {
    if (scenarios[selectedScenario] && scenarios[selectedScenario][mode]) {
      const scenarioData = scenarios[selectedScenario][mode];
      setData(scenarioData);

      const uniqueLocations = Array.from(new Set(scenarioData.flatMap(({ origin, destination }) => [origin, destination])));
      setLocations(uniqueLocations);

      const initialFilters = initializeFilters(scenarioData);
      const savedFilters = filtersRef.current || initialFilters;
      filtersRef.current = savedFilters; // Save initial or saved filters to ref
      onFilterChange(savedFilters);
      applyFilters(savedFilters, scenarioData);
    }
  }, [scenarios, selectedScenario, mode]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      applyFilters(filters, data);
    }
  }, [filters, data]);

  const initializeFilters = (csvData) => {
    const headers = Object.keys(csvData[0]);
    const filterOptions = {};
    headers.forEach(header => {
      if (header !== 'origin' && header !== 'destination' && header !== 'value') {
        filterOptions[header] = [...new Set(csvData.map(row => row[header]))];
      }
    });

    const initialFilters = {};
    Object.keys(filterOptions).forEach(filter => {
      initialFilters[filter] = {};
      filterOptions[filter].forEach(option => {
        initialFilters[filter][option] = false;
      });
    });

    setFilterOptions(filterOptions);
    return initialFilters;
  };

  const applyFilters = (newFilters, dataToFilter) => {
    const activeFilters = {};
    Object.keys(newFilters).forEach(filter => {
      activeFilters[filter] = Object.keys(newFilters[filter]).filter(key => newFilters[filter][key]);
    });

    const filteredData = dataToFilter.filter(row => {
      return Object.keys(activeFilters).every(filter => {
        return activeFilters[filter].length === 0 || activeFilters[filter].includes(row[filter]);
      });
    });

    setFilteredData(filteredData);
  };

  const handleScenarioChange = (event) => {
    const scenario = event.target.value;
    onScenarioChange(scenario);
  };

  const handleCheckboxChange = (event, filterType) => {
    const { name, checked } = event.target;
    const newFilters = {
      ...filters,
      [filterType]: {
        ...filters[filterType],
        [name]: checked,
      },
    };
    filtersRef.current = newFilters; // Save to ref
    onFilterChange(newFilters); // Update filter state
    applyFilters(newFilters, data); // Apply filters to the data
  };

  useEffect(() => {
    const rowTotals = {};
    const colTotals = {};
    locations.forEach(location => {
      rowTotals[location] = 0;
      colTotals[location] = 0;
    });

    filteredData.forEach(row => {
      rowTotals[row.origin] += Number(row.value);
      colTotals[row.destination] += Number(row.value);
    });

    setOriginTotals(rowTotals);
    setDestinationTotals(colTotals);
  }, [filteredData, locations]);

  return (
    <div>
      <div className="top-half">
          {/* <h2>{mode}</h2> */}
          <MatrixTable data={filteredData} locations={locations} />
      </div>
      <div className="filters-container">
        <ScenarioSelector
          scenarios={Object.keys(scenarios)}
          selectedScenario={selectedScenario}
          onScenarioChange={handleScenarioChange}
        />
        {Object.keys(filterOptions).map(filter => (
          filterOptions[filter].length > 0 && (
            <FilterPanel
              key={filter}
              title={filter}
              options={filterOptions[filter]}
              handleCheckboxChange={(e) => handleCheckboxChange(e, filter)}
              checkedOptions={filters[filter] || {}}
            />
          )
        ))}
      </div>
      <div className="bottom-half">
        <div className="map-chart-container">
          {geojsonData && (
            <MapComponent
              title="By Origin"
              geojsonData={geojsonData}
              totals={originTotals}
              initialViewState={{
                longitude: geojsonCenter[0],
                latitude: geojsonCenter[1],
                zoom: geojsonZoom,
                pitch: 0,
                bearing: 0
              }}
            />
          )}
          <BarChartComponent title="Origin Totals" totals={originTotals} />
        </div>
        <div className="map-chart-container">
          {geojsonData && (
            <MapComponent
              title="By Destination"
              geojsonData={geojsonData}
              totals={destinationTotals}
              initialViewState={{
                longitude: geojsonCenter[0],
                latitude: geojsonCenter[1],
                zoom: geojsonZoom,
                pitch: 0,
                bearing: 0
              }}
            />
          )}
          <BarChartComponent title="Destination Totals" totals={destinationTotals} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
