import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const SummaryChart = ({ scenarios, modes }) => {
  const labels = Object.keys(scenarios);
  const datasets = Object.keys(modes).map((modeKey, index) => ({
    label: modes[modeKey],
    data: labels.map(scenarioName => {
      const scenario = scenarios[scenarioName];
      return scenario[modeKey] ? scenario[modeKey].reduce((sum, item) => sum + Number(item.value), 0) : 0;
    }),
    backgroundColor: `rgba(${index * 50}, ${100 + index * 50}, ${150 + index * 50}, 0.6)`
  }));

  const data = {
    labels,
    datasets
  };

  const options = {
    plugins: {
      legend: {
        position: 'top'
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };

  return <Bar data={data} options={options} />;
};

export default SummaryChart;
