import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import * as turf from '@turf/turf';
import './Home.css';

const Home = ({ onFilesUploaded }) => {
  const [files, setFiles] = useState([]);
  const [geojsonFile, setGeojsonFile] = useState(null);
  const [geojsonCenter, setGeojsonCenter] = useState(null);
  const [geojsonZoom, setGeojsonZoom] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState(""); // Add state for feedback message
  const [enableUpload, setEnableUpload] = useState(false);

  const roundToOneDecimal = (value) => {
    return Math.round(value * 10) / 10;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onprogress = (event) => {
        if (event.loaded && event.total) {
          const percent = (event.loaded / event.total) * 100;
          setUploadProgress(percent);
        }
      };

      reader.onload = () => {
        if (file.name.endsWith('.geojson')) {
          const geojsonData = JSON.parse(reader.result);
          setGeojsonFile({ file, data: geojsonData });
          const center = turf.center(geojsonData).geometry.coordinates;
          setGeojsonCenter(center);
          const bbox = turf.bbox(geojsonData);
          const bboxPolygon = turf.bboxPolygon(bbox);
          const diagonal = turf.distance(
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
          );
          const zoom = 14 - Math.log2(diagonal);
          setGeojsonZoom(zoom);
        } else {
          Papa.parse(file, {
            complete: (result) => {
              const roundedData = result.data
                .filter(row => row.origin && row.destination && row.value) // Filter out empty rows
                .map(row => ({
                  ...row,
                  value: roundToOneDecimal(Number(row.value))
                }));

              // Get unique zones
              const uniqueZones = new Set();
              roundedData.forEach(row => {
                uniqueZones.add(row.origin);
                uniqueZones.add(row.destination);
              });

              if (uniqueZones.size > 100) {
                setFeedbackMessage("Too many zones! This version of the app works best with up to 100 zones.");
                setEnableUpload(false);
              } else {
                setFiles((prevFiles) => [...prevFiles, { file, data: roundedData }]);
                setFeedbackMessage(""); // Clear feedback message
                setEnableUpload(true); // Enable upload if the number of zones is within the limit
              }

              setUploadProgress(0); // Reset progress
            },
            header: true,
            skipEmptyLines: true, // Skip empty lines
          });
        }
      };

      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.file !== fileToRemove));
    if (geojsonFile && geojsonFile.file === fileToRemove) {
      setGeojsonFile(null);
      setGeojsonCenter(null);
      setGeojsonZoom(null);
    }
  };

  const handleUpload = () => {
    const modes = {};
    const scenarios = {};

    files.forEach(({ file, data }) => {
      const parts = file.name.split('_');
      const scenario = parts[0];
      const mode = parts[1].split('.')[0]; // Remove file extension

      if (!modes[mode]) {
        modes[mode] = mode;
      }

      if (!scenarios[scenario]) {
        scenarios[scenario] = {};
      }

      // Ensure the data is correctly rounded
      const roundedData = data.map(row => ({
        ...row,
        value: Number(row.value)
      }));

      scenarios[scenario][mode] = roundedData;
    });

    onFilesUploaded({ modes, scenarios, files, geojsonFile, geojsonCenter, geojsonZoom });
    setFeedbackMessage("Data has been processed successfully!"); // Set feedback message
  };

  return (
    <div className="home-page">
      <div className="instructions">
        <h2>Matrix Explorer</h2>
        <h3>Upload Your Transport Sector Matrix Data</h3>
        <p>
          Please upload your CSV files with the following format:
          <ul>
            <li>The file name should be in the format: <strong>scenario_mode.csv</strong></li>
            <li>For example: <strong>base_pt.csv</strong> or <strong>scenario1_vehs.csv</strong></li>
            <li>The CSV file should contain the following columns: <strong>origin, destination, value</strong></li>
            <li>Additional columns can be used for filters (e.g., period, purpose)</li>
            <li>This version of the app works best with up to 100 zones</li>
          </ul>
        </p>
        <p>Additionally, you can upload a GeoJSON file to visualise zones on a map. The GeoJSON must be in the EPSG:4326/WGS84 projection and have a column called "ID" that relates to the matrix zone names.</p>
        <p>
          You can download a zip file of sample data <a href="https://raw.githubusercontent.com/mobilitylabnz/matrix-explorer/main/sample-data.zip" download="sample-data.zip">here</a>.
        </p>
        <p>If you have any questions or comments, please contact <a href="mailto:support@mobilitylab.co.nz">support@mobilitylab.co.nz</a>.</p>
      </div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {uploadProgress > 0 && <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>}
      <div className="file-list">
        {files.map(({ file }, index) => (
          <div key={index} className="file-item">
            <span>{file.name}</span>
            <FontAwesomeIcon 
              icon={faTrashAlt} 
              className="remove-icon" 
              onClick={() => handleRemoveFile(file)} 
            />
          </div>
        ))}
        {geojsonFile && (
          <div className="file-item">
            <span>{geojsonFile.file.name}</span>
            <FontAwesomeIcon 
              icon={faTrashAlt} 
              className="remove-icon" 
              onClick={() => handleRemoveFile(geojsonFile.file)} 
            />
          </div>
        )}
      </div>
      {files.length > 0 && (
        <button className="upload-button" onClick={handleUpload} disabled={!enableUpload}>
          Upload Files
        </button>
      )}
      {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
    </div>
  );
};

export default Home;
